/* eslint-disable @typescript-eslint/no-explicit-any */
import { GetServerSideProps } from 'next';

import { PageRendererComponent, prePageCall } from '@marriott/mi-headless-utils';
import { processDynamicProps, updateDataLayer } from '@marriott/mi-offers-components';
import { mockOffersApiResponse } from '../mock/mockOffersApiResponse';
import { fetchOffersSpecificData } from '@marriott/mi-offers-components';
import { isLocalDev } from '@marriott/mi-apollo-client-utils';
import OfferSignatures from '../signatures/offerSignatures.json';
import { NEXT_PUBLIC_ENV_KEYS } from '@marriott/mi-offers-components';

const { LOCAL_UXL_CONTENT_URI } = process.env;

function pageRenderer(props) {
  if (isLocalDev) {
    console.log('[page] props - ', props);
  }
  if (!props.isSeoDisable && Object.keys(props?.productSpecificResponse)?.length) {
    updateDataLayer(props);
    processDynamicProps(props);
  }
  return <PageRendererComponent {...props} />;
}

//Any product specific page data apart from common data like session, datalayer
//can be returned from this function and the same is passed as props to the page Component
const offersSpecificData = async (context: any, parallelCallsResponse: any, interceptorResponse: any) => {
  const req = context?.req;
  console.log('[page] offers context -', context, 'context resolvedUrl -', context?.resolvedUrl, 'req -', req);
  console.log('[page] parallelCallsResponse ', parallelCallsResponse);
  console.log('[page] interceptorResponse ', interceptorResponse);

  const resolvedUrlString: string = req?.url;
  const isAuthorMode = resolvedUrlString.includes('isAuthorMode');
  const currentLocale = interceptorResponse?.requestAttributes?.pageManagerRequest?.global?.localeKey?.replace(
    '_',
    '-'
  );
  const offersSpecificData = isAuthorMode
    ? mockOffersApiResponse
    : await fetchOffersSpecificData(context, currentLocale);
  console.log('service api response::', offersSpecificData);
  let uri = offersSpecificData?.response?.uri;
  if (uri && isLocalDev && !isAuthorMode) {
    const resolvedOfferType = uri.split('offers-').pop().split('/')[0];
    uri = `${LOCAL_UXL_CONTENT_URI}${resolvedOfferType}`;
  } else if (typeof uri == 'undefined') {
    uri = null;
  }

  return {
    operationSignatures: OfferSignatures,
    productSpecificResponse: offersSpecificData?.response,
    uri: uri,
    isSeoDisable: isAuthorMode,
    nextPublicEnvKeys: NEXT_PUBLIC_ENV_KEYS,
  };
};

//Make pre page calls like session, datalayer, fetch model.json, producSpecifc data etc
//at server side and pass the data is passed as props to page component.
export const getServerSideProps: GetServerSideProps<any> = prePageCall(offersSpecificData);
export default pageRenderer;
